// context.tsx
import createCache from "@emotion/cache";
import { withEmotionCache } from "@emotion/react";
import { createContext, useContext, useEffect } from "react";

export interface ServerStyleContextData {
  key: string;
  ids: Array<string>;
  css: string;
}

export const ServerStyleContext = createContext<
  ServerStyleContextData[] | null
>(null);

export interface ClientStyleContextData {
  reset: () => void;
}

export const ClientStyleContext = createContext<ClientStyleContextData | null>(
  null
);

export const defaultCache = createEmotionCache();

export default function createEmotionCache() {
  return createCache({ key: "cha" });
}

export const ChakraStyle = withEmotionCache((props, emotionCache) => {
  const serverStyleData = useContext(ServerStyleContext);
  const clientStyleData = useContext(ClientStyleContext);

  // Only executed on client
  useEffect(() => {
    // re-link sheet container
    emotionCache.sheet.container = document.head;
    // re-inject tags
    const tags = emotionCache.sheet.tags;
    emotionCache.sheet.flush();
    tags.forEach((tag) => {
      (emotionCache.sheet as any)._insertTag(tag);
    });
    // reset cache to reapply global styles
    clientStyleData?.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return serverStyleData?.map(({ key, ids, css }) => (
    <style
      key={key}
      data-emotion={`${key} ${ids.join(" ")}`}
      dangerouslySetInnerHTML={{ __html: css }}
    />
  ));
});
